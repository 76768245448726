// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { AVAILABLE_MODELS } from 'src/data/models/nlp-models';
import { capitalCase } from 'change-case';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'home',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: 'Data Preparation',
        path: PATH_DASHBOARD.general.data_cleaning_module,
        icon: ICONS.menuItem,
        children: [
          {
            title: 'Data Cleaning',
            path: PATH_DASHBOARD.general.data_cleaning_module,
          },
          {
            title: 'Stop Words',
            path: PATH_DASHBOARD.general.stopwords,
          },
        ],
      },
      {
        title: 'NLP Models',
        path: PATH_DASHBOARD.general.nlp_models,
        icon: ICONS.analytics,
        children: Object.keys(AVAILABLE_MODELS).map((key) => ({
          title: capitalCase(key),
          path: `${PATH_DASHBOARD.general[key]}`,
        })),
      },
      {
        title: 'Workflows',
        path: PATH_DASHBOARD.general.workflows,
        icon: ICONS.kanban,
      },
      {
        title: 'Projects',
        path: PATH_DASHBOARD.general.projects,
        icon: ICONS.kanban,
      },
    ],
  },
];

export default navConfig;
