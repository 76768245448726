import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

export const BATCH_PROCESS_STAGES = {
  UPLOAD: 'UPLOAD',
  SELECT: 'SELECT',
  PROCESS: 'PROCESS',
  DONE: 'DONE',
};

const initialState = {
  // Input text
  inputText: '',
  // data
  data: null,
  // loading
  loading: false,
  // error
  error: null,
  // sentiment
  sentiment: null,
  // batch process stage
  batchProcessStage: BATCH_PROCESS_STAGES.UPLOAD,
  // texts to be classified
  texts: [],
  // uploaded data
  uploadedData: null,
  // selected column for classification
  selectedColumn: null,
  // filter
  filter: null,
  // project saving
  isProjectSaving: false,
  // project saved
  projectSaved: false,
  // project dialog open
  isProjectDialogOpen: false,
  // project name
  projectName: '',
  // remove stop words
  removeStopWords: true,
  // remove punctuation
  removePunctuation: true,
  // lower case
  lowerCase: true,
  // removed stop words
  removedStopWords: '',
  // added stop words
  addedStopWords: '',
};

const slice = createSlice({
  name: 'sentimentAnalysis',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
      state.data = null;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },

    // Profile Update Success
    getDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },

    // Reset Data
    resetData(state) {
      state.inputText = '';
      state.data = null;
      state.loading = false;
      state.error = null;
      state.sentiment = null;
      state.batchProcessStage = BATCH_PROCESS_STAGES.UPLOAD;
      state.texts = [];
      state.uploadedData = null;
      state.selectedColumn = null;
      state.filter = null;
      state.isProjectSaving = false;
      state.projectSaved = false;
      state.isProjectDialogOpen = false;
      state.projectName = '';
      state.removeStopWords = true;
      state.removePunctuation = true;
      state.lowerCase = true;
      state.removedStopWords = '';
      state.addedStopWords = '';
    },

    // set input text
    setInputText(state, action) {
      state.inputText = action.payload;
    },

    // set batch process stage
    setBatchProcessStage(state, action) {
      state.batchProcessStage = action.payload;
    },

    // set texts
    setTexts(state, action) {
      state.texts = action.payload;
    },

    // set uploaded data
    setUploadedData(state, action) {
      state.uploadedData = action.payload;
    },

    // set selected column
    setSelectedColumn(state, action) {
      state.selectedColumn = action.payload;
    },

    // set project save state
    setProjectIsSaving(state, action) {
      state.isProjectSaving = true;
    },

    // set project saved
    setProjectSaved(state, action) {
      state.isProjectSaving = false;
      state.projectSaved = true;
    },

    // set filter
    setFilter(state, action) {
      state.filter = action.payload;
    },

    // set project dialog open
    setProjectDialogOpen(state, action) {
      state.isProjectDialogOpen = action.payload;
    },

    // set project name
    setProjectName(state, action) {
      state.projectName = action.payload;
    },

    // set remove stop words
    setRemoveStopWords(state, action) {
      state.removeStopWords = action.payload;
    },

    // set remove punctuation
    setRemovePunctuation(state, action) {
      state.removePunctuation = action.payload;
    },

    // set lower case
    setLowerCase(state, action) {
      state.lowerCase = action.payload;
    },

    // set add stop words
    setAddedStopWords(state, action) {
      state.addedStopWords = action.payload;
    },

    // set remove stop words
    setRemovedStopWords(state, action) {
      state.removedStopWords = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function getSentimentForInput(modelType) {
  return async (dispatch, state) => {
    const { removeStopWords, removePunctuation, lowerCase } =
      state().sentimentAnalysis;

    dispatch(actions.startLoading());
    try {
      const { inputText, addedStopWords, removedStopWords } =
        state().sentimentAnalysis;
      const response = await axios.post(
        `/api/v1/ml-models/sentiment-analysis/${modelType}/`,
        {
          texts: [inputText],
          remove_stopwords: removeStopWords,
          remove_punct_num: removePunctuation,
          lowercase: lowerCase,
          added_stopwords: addedStopWords
            .split(',')
            .map((word) => word.trim())
            .filter((word) => word !== ''),
          removed_stopwords: removedStopWords
            .split(',')
            .map((word) => word.trim())
            .filter((word) => word !== ''),
        }
      );
      dispatch(actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}

export function getSentimentForBatch(modelType) {
  return async (dispatch, state) => {
    const {
      selectedColumn,
      uploadedData: { parsedData },
    } = state().sentimentAnalysis;
    dispatch(actions.startLoading());
    try {
      const response = await axios.post(
        `/api/v1/ml-models/sentiment-analysis/${modelType}/`,
        { texts: parsedData.map((item) => item[selectedColumn]) }
      );
      dispatch(actions.getDataSuccess(response.data));
      dispatch(actions.setBatchProcessStage(BATCH_PROCESS_STAGES.DONE));
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}

export function saveProject(modelName, name) {
  return async (dispatch, state) => {
    const {
      selectedColumn,
      uploadedData: { parsedData },
    } = state().sentimentAnalysis;
    dispatch(actions.setProjectIsSaving(true));
    try {
      await axios.post(`/api/v1/projects/save-project/`, {
        texts: parsedData.map((item) => item[selectedColumn]),
        model_name: modelName,
        project_name: name,
        model_type: 'sentiment_analysis',
      });
      dispatch(actions.setProjectIsSaving(true));
      dispatch(actions.setProjectSaved(true));
    } catch (error) {
      dispatch(actions.hasError(error));
      dispatch(actions.setProjectIsSaving(false));
    }
  };
}
