// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    nlp_models: path(ROOTS_DASHBOARD, '/nlp-models'),
    sentiment_analysis: path(ROOTS_DASHBOARD, '/nlp-models/sentiment-analysis'),
    keyword_extraction: path(ROOTS_DASHBOARD, '/nlp-models/keyword-extraction'),
    workflows: path(ROOTS_DASHBOARD, '/workflows'),
    create_workflow: path(ROOTS_DASHBOARD, '/workflows/create'),
    projects: path(ROOTS_DASHBOARD, '/projects'),
    data_cleaning_module: path(
      ROOTS_DASHBOARD,
      '/data-cleaning-module/data-cleaning'
    ),
    stopwords: path(ROOTS_DASHBOARD, '/data-cleaning-module/stopwords'),
  },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),

    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
};

export const PATH_DOCS = '';
export const API_DOCS = '';
